import React, { Component } from "react";
import { ReceiverConfig } from "./ReceiverConfig";
import { WSClient } from "../api/WSClient";
import {
  LoginStream,
  updateLoginStream,
  Point,
  updateLoginPoints,
} from "../store/Login";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DepositStream, updateDepositStream } from "../store/Deposit";
import {
  updateCustomerStream,
  CustomerStream,
  updateCustomerPoints,
} from "../store/Customer";
import { RootState } from "../store/Reducer";
import { updateModalIsVisible } from "../store/Modal";
import "./ReceiverPage.scss";
import { DateTime } from "luxon";
import { Leaderboard } from "./Leaderboard/Leaderboard";
import { GeneralDashboard } from "./GeneralDashboard/GeneralDashboard";
import { GameStream, updateGameStream } from "../store/Game";
import { DashboardStream, updateDashboardStream } from "../store/Dashboard";

const WS_PATH = "https://metrics-tv.xn--mrka-5qa.se/stream";
const PAGE_TIME = 30000; //ms

interface State {
  activePage: number;
}

interface StateProps {
  loginStream: LoginStream[];
  loginPoints: Point[];
  customerPoints: Point[];
  depositStream: DepositStream[];
  customerStream: CustomerStream[];
  gameStream: GameStream[];
  dashboardStream: DashboardStream;
}

interface DispatchProps {
  updateLoginStream: (newStream: LoginStream) => void;
  updateLoginPoints: (newPoints: Point[]) => void;
  updateDepositStream: (newStream: DepositStream) => void;
  updateCustomerStream: (newStream: CustomerStream) => void;
  updateModalIsVisible: (isVisible: boolean) => void;
  updateCustomerPoints: (newPoints: Point[]) => void;
  updateGameStream: (gameStream: GameStream[]) => void;
  updateDashboardStream: (dashboardStream: DashboardStream) => void;
}

type Props = DispatchProps & StateProps;

class Receiver extends Component<Props, State> {
  private receiverConfig: ReceiverConfig;
  private wsClient: WSClient;
  private pages: JSX.Element[] = [];
  private timer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    const script = document.createElement("script");
    script.src =
      "https://www.gstatic.com/cast/sdk/libs/receiver/2.0.0/cast_receiver.js";
    script.async = true;
    document.body.appendChild(script);
    this.receiverConfig = new ReceiverConfig();
    this.state = {
      activePage: 0,
    };
    this.wsClient = new WSClient(WS_PATH);
    this.wsClient.connect(() => {
      this.setupSubscribers();
    });
  }

  private setupSubscribers() {
    this.wsClient.subscribe("/leaderboard", (res) => {
      const games: GameStream[] = JSON.parse(res.body);
      this.props.updateGameStream(games);
    });
    this.wsClient.subscribe("/dashboard", (res) => {
      const dashboard: DashboardStream = JSON.parse(res.body);
      this.props.updateDashboardStream(dashboard);
    });
  }

  private setupPages() {
    this.pages = [];

    if (this.props.gameStream && this.props.gameStream.length > 0) {
      this.pages.push(<Leaderboard game={this.props.gameStream[0]} />);
    }
    if (
      this.props.dashboardStream &&
      this.props.dashboardStream.slack !== undefined
    ) {
      this.pages.push(
        <GeneralDashboard dashboard={this.props.dashboardStream} />
      );
    }
  }

  private midnightClear() {
    setTimeout(() => {
      this.props.updateCustomerPoints([]);
      this.midnightClear();
    }, DateTime.fromFormat("24:00:00", "HH:mm:ss").diff(DateTime.fromJSDate(new Date())).milliseconds);
  }

  componentDidMount() {
    setTimeout(this.receiverConfig.setup, 2000);
    this.rotatePageTimer();
    this.midnightClear();
  }

  rotatePageTimer() {
    this.timer = setTimeout(() => {
      this.setupPages();
      if (this.pages.length > 0) {
        const newPage = (this.state.activePage + 1) % this.pages.length;
        this.setState({
          activePage: newPage,
        });
      }
      this.rotatePageTimer();
    }, PAGE_TIME);
  }

  render() {
    if (this.pages.length > 0) {
      return (
        <React.Fragment>{this.pages[this.state.activePage]}</React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFF",
          }}
        >
          <h1>Väntar på data</h1>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateLoginStream: updateLoginStream(dispatch),
    updateLoginPoints: updateLoginPoints(dispatch),
    updateDepositStream: updateDepositStream(dispatch),
    updateCustomerStream: updateCustomerStream(dispatch),
    updateModalIsVisible: updateModalIsVisible(dispatch),
    updateCustomerPoints: updateCustomerPoints(dispatch),
    updateGameStream: updateGameStream(dispatch),
    updateDashboardStream: updateDashboardStream(dispatch),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    loginStream: state.logins.stream,
    loginPoints: state.logins.points,
    depositStream: state.deposits.stream,
    customerPoints: state.customers.points,
    customerStream: state.customers.stream,
    gameStream: state.game.gameStream,
    dashboardStream: state.dashboard.dashboardStream,
  };
};

export const ReceiverPage = connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Receiver);
