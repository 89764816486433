import { combineReducers } from "redux";
import { loginReducer, LoginState } from "./Login";
import { customerReducer, CustomerState } from "./Customer";
import { depositReducer, DepositState } from "./Deposit";
import { ModalState, modalReducer } from "./Modal";
import { gameReducer, GameState } from "./Game";
import { dashboardReducer, DashboardState } from "./Dashboard";

export interface RootState {
  logins: LoginState;
  customers: CustomerState;
  deposits: DepositState;
  modal: ModalState;
  game: GameState;
  dashboard: DashboardState;
}

export const rootReducer = combineReducers({
  logins: loginReducer,
  customers: customerReducer,
  deposits: depositReducer,
  modal: modalReducer,
  game: gameReducer,
  dashboard: dashboardReducer,
});
