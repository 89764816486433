import { Dispatch, AnyAction } from "redux";

const UPDATE_DASHBOARD_STREAM = "UPDATE_DASHBOARD_STREAM";

export interface DashboardState {
  dashboardStream: DashboardStream;
}

const initialState: DashboardState = {
  dashboardStream: { slack: 0 },
};

export interface DashboardStream {
  slack: number;
}

export interface CustomerTotal {
  today: string;
}

interface UpdateStreamAction {
  type: typeof UPDATE_DASHBOARD_STREAM;
  stream: DashboardStream;
}

type GameActions = UpdateStreamAction;

export function updateDashboardStream(dispatch: Dispatch<AnyAction>) {
  return (newDashboard: DashboardStream): void => {
    dispatch({
      type: UPDATE_DASHBOARD_STREAM,
      stream: newDashboard,
    });
  };
}

export function dashboardReducer(state = initialState, action: GameActions) {
  if (!state) {
    return state;
  }
  switch (action.type) {
    case UPDATE_DASHBOARD_STREAM: {
      const newState = {
        ...state,
        dashboardStream: action.stream,
      };
      return newState;
    }
    default:
      return state;
  }
}
