const applicationID = "08D129E7";
const namespace = ["urn:x-cast:se.lysa.dashboard"];
let session: any = null;

export class SenderConfig {
  setup() {
    const chrome: any = (window as any).chrome;
    if (!chrome.cast || !chrome.cast.isAvailable) {
      setTimeout(this.initializeCastApi, 2000);
    }
  }

  connect() {
    console.log(`Connecting to ${window.location.href + "receiver"}`);
    this.sendMessage({
      type: "load",
      url: window.location.href + "receiver",
      refresh: 0,
    });
  }

  private initializeCastApi() {
    const chrome: any = (window as any).chrome;
    const sessionRequest = new chrome.cast.SessionRequest(applicationID);
    const apiConfig = new chrome.cast.ApiConfig(
      sessionRequest,
      this.sessionListener,
      () => {}
      //this.receiverListener
    );
    chrome.cast.initialize(apiConfig, this.onInitSuccess, this.onError);
  }

  private onInitSuccess = () => {
    console.log("onInitSuccess");
  };

  private onSuccess = (message: any) => {
    console.log("onSuccess: " + JSON.stringify(message));
  };

  private onError = (message: any) => {
    console.log("onError: " + JSON.stringify(message));
  };

  private sessionListener = (e: any) => {
    console.log("New session ID: " + e.sessionId);
    session = e;
    session.addUpdateListener(this.sessionUpdateListener);
  };

  private receiverListener = (e: any) => {
    // Due to API changes just ignore this.
    console.log(`receiverListener: ${e}`);
  };

  private sessionUpdateListener = (isAlive: boolean) => {
    console.log(
      (isAlive ? "Session Updated" : "Session Removed") +
        ": " +
        session.sessionId
    );
    if (!isAlive) {
      session = null;
    }
  };

  private sendMessage = (message: any) => {
    if (session != null) {
      session.sendMessage(namespace, message, this.onSuccess, this.onError);
    } else {
      const chrome: any = (window as any).chrome;
      chrome.cast.requestSession((e: any) => {
        session = e;
        this.sessionListener(e);
        session.sendMessage(namespace, message, this.onSuccess, this.onError);
      }, this.onError);
    }
  };
}
