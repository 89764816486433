import React, { FunctionComponent } from "react";
import { GameStream } from "../../store/Game";
import "./Leaderboard.scss";

interface Props {
  game?: GameStream;
}

export const Leaderboard: FunctionComponent<Props> = ({ game }) => {
  console.log("GAME", game);

  console.log("leadeboard", game?.leaderboard);

  if (game === undefined) {
    return null;
  }

  return (
    <div
      className="leaderboard"
      style={{
        backgroundImage: `url(${
          "data:image/jpg;base64," + game.leaderboard.image ||
          "https://images.pexels.com/photos/4523021/pexels-photo-4523021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        }`,
      }}
    >
      <div className="leaderboard-inner">
        <div className="header">
          <h2>{game.leaderboard.name}</h2>
          {/* <div className="outer-podium-wrapper">
            {game.scores[1] && (
              <div className="podium-wrapper">
                <div className="podium second-place">
                  <span>T</span>
                </div>
                <span>#2 {game.scores[1].name}</span>
              </div>
            )}
            {game.scores[0] && (
              <div className="podium-wrapper">
                <div className="podium winner">
                  <span>M</span>
                </div>
                <span>#1 {game.scores[0].name}</span>
              </div>
            )}
            {game.scores[2] && (
              <div className="podium-wrapper">
                <div className="podium third-place">
                  <span>H</span>
                </div>
                <span>#3 {game.scores[2].name}</span>
              </div>
            )}
          </div> */}
        </div>
        <table>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Score</th>
            <th>Times played</th>
            <th>Average score</th>
          </tr>
          {game.scores?.map((score) => {
            return (
              <tr>
                <td className="center">
                  {score.placement === 1 && `👑`}{" "}
                  {score.placement > 1 && score.placement}
                </td>
                <td>{score.name}</td>
                <td>{score.score}</td>
                <td>{score.timesPlayed}</td>
                <td>{score.average}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};
