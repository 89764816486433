import React, { FunctionComponent } from "react";
import { DashboardStream } from "../../store/Dashboard";
import "./GeneralDashboard.scss";

interface Props {
  dashboard: DashboardStream;
}

export const GeneralDashboard: FunctionComponent<Props> = ({ dashboard }) => {
  return (
    <div className="dashboard">
      <div className="dashboard-inner">
        <div className="header">
          <h2>📊 Lysa Stats</h2>
        </div>
        <div className="card">
          <div className="card-header">💬 Slack interactions last hour</div>
          <p>{dashboard.slack}</p>
        </div>
      </div>
    </div>
  );
};
