import { Dispatch, AnyAction } from "redux";

const UPDATE_GAME_STREAM = "UPDATE_GAME_STREAM";
// const UPDATE_GAME_TOTAL = "UPDATE_GAME_TOTAL";
// const UPDATE_GAME_POINTS = "UPDATE_GAME_POINTS";

export interface GameState {
  // stream: GameStream[];
  // game: GameStream[];
  gameStream: GameStream[];
}

const initialState: GameState = {
  // stream: [],
  gameStream: [],
};

interface Board {
  image: string;
  name: string;
}

interface Score {
  name: string;
  score: number;
  timesPlayed: number;
  average: number;
  placement: number;
}

export interface GameStream {
  leaderboard: Board;
  scores: Score[];
}

export interface CustomerTotal {
  today: string;
}

interface UpdateStreamAction {
  type: typeof UPDATE_GAME_STREAM;
  stream: GameStream;
}

// interface UpdateTotalAction {
//   type: typeof UPDATE_GAME_TOTAL;
//   total: CustomerTotal;
// }

// interface UpdatePointsAction {
//   type: typeof UPDATE_GAME_POINTS;
//   points: Point[];
// }

type GameActions = UpdateStreamAction;

export function updateGameStream(dispatch: Dispatch<AnyAction>) {
  // console.log("test");

  return (newGame: GameStream[]): void => {
    // console.log("dispatch", newGame);

    dispatch({
      type: UPDATE_GAME_STREAM,
      stream: newGame,
    });
  };
}

export function gameReducer(state = initialState, action: GameActions) {
  // console.log("action", action);

  if (!state) {
    return state;
  }
  switch (action.type) {
    case UPDATE_GAME_STREAM: {
      const newState = {
        ...state,
        gameStream: action.stream,
      };

      // console.log("newState", newState);
      return newState;
    }
    default:
      return state;
  }
}
